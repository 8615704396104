'use client'

import { JSX } from 'react'
import { useDialog } from '@/hooks/use-dialog'

export default function Modal(): JSX.Element {
  const { component } = useDialog()

  return <>{component}</>
}
