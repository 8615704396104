import { JSX, ReactNode } from 'react'
import { create } from 'zustand'

export interface ModalProps {
  isOpen: boolean
  open: (component: JSX.Element) => void
  close: () => void
  component: ReactNode | null
}

export const useModal = create<ModalProps>((set) => ({
  isOpen: false,
  open: (component: JSX.Element) => set({ isOpen: true, component }),
  close: () => set({ isOpen: false, component: null }),
  component: null,
}))
