'use client'

import { JSX } from 'react'
import { useModal } from '@/hooks/use-modal'

export default function Modal(): JSX.Element {
  const { component } = useModal()

  return <>{component}</>
}
