'use client'

import { AuthProvider } from '@/providers/auth-provider'
import { Toaster } from '@/components/ui/sonner'
import React, { ReactNode } from 'react'
import { ThemeProvider } from 'next-themes'
import Modal from '@/components/commons/modal'
import Dialog from '@/components/commons/dialog'

export default function Providers({ children }: { children: ReactNode }) {
  return (
    <ThemeProvider
      attribute="class"
      defaultTheme="system"
      enableSystem
      disableTransitionOnChange
    >
      <AuthProvider>
        <>
          <Modal />
          <Dialog />
          {children}
          <Toaster />
        </>
      </AuthProvider>
    </ThemeProvider>
  )
}
