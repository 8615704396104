'use client'

import React, { createContext, JSX, useEffect, useMemo, useState } from 'react'
import { Session, User } from '@prisma/client'
import { deleteSession, getSession, setSession } from '@/actions/board'
import { Logos } from '@/components/commons/logos'

export interface AuthContextProps {
  signIn: (user: User) => Promise<void>
  signOut: () => Promise<void>
  user: User | null
}

export const AuthContext: React.Context<AuthContextProps> =
  createContext<AuthContextProps>({
    signIn: async (): Promise<void> => {},
    signOut: async (): Promise<void> => {},
    user: null,
  })

export function AuthProvider({
  children,
}: {
  children: JSX.Element
}): JSX.Element {
  const [loading, setLoading] = useState<boolean>(true)
  const [user, setUser] = useState<User | null>(null)

  useEffect((): void => {
    getSession()
      .then(
        (
          session:
            | (Session & {
                user: User
              })
            | null
        ): void => {
          if (session) {
            setUser(session?.user)
          }
        }
      )
      .finally((): void => {
        setLoading(false)
      })
  }, [])

  const signIn = async (user: User): Promise<void> => {
    await setSession(user)

    setUser(user)
  }

  const signOut = async (): Promise<void> => {
    await deleteSession()

    setUser(null)
  }

  const authContextValue = useMemo(
    () => ({
      signIn,
      signOut,
      user,
    }),
    [user]
  )

  if (loading) {
    return (
      <div className="flex h-screen items-center justify-center bg-background">
        <Logos.short className="size-10 animate-spin fill-muted-foreground" />
      </div>
    )
  }

  return (
    <AuthContext.Provider value={authContextValue}>
      {children}
    </AuthContext.Provider>
  )
}
